<template>
  <div class="Wrap-Section">
    <section>
      <div class="PaymentSystems">
        <h3 class="Title Title-h3 PaymentSystems-Title">
          more than <span class="Colored">200</span> payment systems
        </h3>
        <div class="PaymentSystems-ImgBox">
          <img
            v-for="logo in logos"
            :key="logo.id"
            :src="require(`@/assets/img/${logo.logo}`)"
            alt="payment logo"
            class="PaymentSystems-Img"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PaymentSystems',
  data() {
    return {
      logos: [
        { logo: 'visa.svg' },
        { logo: 'maestro.svg' },
        { logo: 'skrill.svg' },
        { logo: 'paysafe.svg' },
        { logo: 'wirecard.svg' },
        { logo: 'sofort.svg' },
        { logo: 'bitcoin.svg' },
        { logo: 'zimpler.svg' },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.Wrap-Section {
  padding: 50px 0 25px 0 !important;
}

.PaymentSystems {
  @media (min-width: $screen-m) {
    padding: 50px 0;
  }

  &-Title {
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    @media (min-width: $screen-m) {
      margin-bottom: 60px;
      font-size: 46px;
      line-height: 56px;
    }
  }

  &-ImgBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
  }

  &-Img {
    max-height: 20px;
    margin: 0 5px 20px 5px;

    @media (min-width: $screen-m) {
      max-height: 28px;
      margin: 0 20px 20px 20px;
    }
  }
}
</style>
