<template>
  <div class="Wrap-Section">
    <section>
      <div class="Logo">
        <img :src="require('@/assets/img/logo.svg')" alt="Logo">
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.Wrap-Section {
  padding-top: 25px!important;
  padding-bottom: 10px!important;
  @media (min-width: $screen-l) {
    padding-bottom: 50px;
  }
}
.Logo {
  text-align: center;
  margin-bottom: 30px;

  @media (min-width: $screen-l) {
    text-align: left;
    margin: 20px 0 50px 0;
  }
}
</style>
