import '@/styles/main.scss'
import Vue from 'vue'
import App from './App.vue'
import VModal from "vue-js-modal";
import Clipboard from 'v-clipboard'
import Meta from 'vue-meta';

Vue.use(VModal);
Vue.use(Clipboard);

Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
});

new Vue({
  render: (h) => h(App)
}).$mount('#app')
