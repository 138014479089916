<template>
  <div class="Wrap-Section">
    <section>
      <div class="DownloadApp">
        <div class="DownloadApp-Content">
          <div class="DownloadApp-ContentInfo">
            <h2 class="Title Title-h2 DownloadApp-Title">
              Download<br />
              the mobile app
            </h2>
            <button class="Btn Btn--common Btn-Download" @click="$props.show">
              Download
            </button>
          </div>
          <img
            :src="require(`@/assets/img/${footerImage}.svg`)"
            alt="image"
            class="DownloadApp-ContentImg"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'DownloadApp',
  props: {
    urlParams: {
      type: String,
      required: true,
    },
    osDetect: {
      type: String,
      required: true,
    },
    show: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      footerImage: 'download-image',
    };
  },
};
</script>

<style scoped lang="scss">
.Wrap-Section {
  background-color: #1f2d61;
}

.DownloadApp {
  padding-bottom: 100px;
  @media (min-width: $screen-m) {
    padding-bottom: 0;
  }

  &-Content {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;

    @media (min-width: $screen-m) {
      flex-direction: row;
      justify-content: space-between;
      padding: 150px 0;
      text-align: left;
    }
  }

  &-ContentInfo {
    width: 100%;
    max-width: 420px;
    @media (min-width: $screen-s) {
      max-width: 100%;
    }
    @media (min-width: $screen-m) {
      width: 100%;
      max-width: 574px;
    }
  }

  &-Title {
    margin-bottom: 29px;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    text-align: center;

    @media (min-width: $screen-m) {
      margin-bottom: 45px;
      padding-right: 40px;
      font-size: 56px;
      line-height: 68px;
      text-align: left;
    }
  }

  &-ContentImg {
    width: 100px;
    margin-top: 15px;

    @media (min-width: $screen-m) {
      width: auto;
      margin-top: 0;
    }
  }

  .Btn-Download {
    width: 100%;
    @media (min-width: $screen-s) {
      width: auto;
    }
  }
}
</style>
