<template>
  <div class="Modal">
    <div class="Modal-Close" @click="hide">
      <img :src="require(`@/assets/img/close-app.svg`)" alt="image" />
    </div>
    <div class="Modal-Content">
      <div class="Modal-Left">
        <div v-for="item in contentInfo" :key="item.id" class="Modal-Item">
          <img
            v-if="platform !== item.platform"
            :class="item.class"
            :src="require(`@/assets/img/${item.image}`)"
            alt="image"
            class="Modal-ItemImg"
          />
          <p
            v-if="platform !== item.platform"
            class="Text Modal-ItemText"
            :class="item.class"
            v-html="item.text"
          ></p>
        </div>
        <template v-if="width < 960">
          <a
            v-if="platform === 'mobile'"
            :href="getParams"
            target="_blank"
            class="Btn Btn--common Btn-Download"
          >
            Download
          </a>
        </template>
      </div>
      <div class="Modal-Right">
        <img :src="require(`@/assets/img/${logo}`)" alt="logo" class="Modal-Logo" />
        <p class="Text Modal-Text">
          Scan QR code with your mobile, or copy the link if you are using mobile.
        </p>
        <img :src="require(`@/assets/img/${qrcode}`)" alt="" class="Modal-QrCode" />
        <template v-if="width > 960">
          <div v-if="platform !== 'android' && width > 960" class="Modal-LinkBox">
            <button v-clipboard:copy="getParams" v-clipboard:success="onCopy" class="Modal-LinkCopy">
              <span>{{ siteLink }}</span>
              <img
                :src="require(`@/assets/img/${iconCopy}`)"
                alt="icon"
                class="Modal-LinkCopyImg"
              />
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getParams, platform, width } from '@/scripts/script';

export default {
  name: 'Modal',
  data() {
    return {
      logo: 'app-logo.svg',
      qrcode: 'qr-code.png',
      iconCopy: 'app-icon-copy.svg',
      siteLink: 'https://ninecas.onelink.me',
      contentInfo: [
        {
          image: 'download.svg',
          text: 'Open the site on mobile or computer and click download the application.',
          class: 'Item--disable',
        },
        {
          image: 'qrcode.svg',
          text: '<span>Scan QR code with mobile, or </span>copy the link to download the app.',
          class: 'Item--disable',
        },
        {
          image: 'shield.svg',
          text: 'Allow to install unknown apps  this source.',
        },
        {
          image: 'settings.svg',
          text: 'After downloading the application, follow the instructions on the screen.',
        },
      ],
    };
  },
  computed: {
    getParams,
    platform,
    width
  },
  methods: {
    hide() {
      this.$modal.hide('modal');
    },
    onCopy() {
      alert(`Successful copied: ${this.getParams}`);
    },
  },
};
</script>

<style lang="scss">
.vm--modal {
  background-color: var(--color-bg-modal) !important;

  @media (min-width: $screen-l) {
    overflow: visible !important;
  }
}

.Modal {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-modal);
  border-radius: 6px;
  padding: 0 15px;

  @media (min-width: $screen-l) {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 392px;
      width: 1px;
      height: 100%;
      background-color: var(--color-modal-border);
    }
  }

  &-Close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    @media (min-width: $screen-xl) {
      top: 0;
      right: -216px;
    }
  }

  &-Content {
    display: flex;
    flex-direction: column-reverse;
    background-color: var(--color-bg-modal);

    @media (min-width: $screen-l) {
      flex-direction: row;
    }
  }

  &-Left {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    padding: 8px 8px 20px;
    text-align: center;

    @media (min-width: $screen-l) {
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: normal;
      width: 392px;
      padding: 57px 30px 48px 30px;
      text-align: left;
    }
  }

  &-Item {
    width: 45%;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: $screen-l) {
      width: 100%;
    }
  }

  &-ItemImg {
    width: 36px;
  }

  &-ItemText {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.66;
    color: var(--color-text-main);

    &:first-letter {
      text-transform: uppercase;
    }

    span {
      display: none;
    }

    @media (min-width: $screen-l) {
      font-size: 16px;

      span {
        display: block;
      }
    }
  }

  &-Right {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px 20px;
    text-align: center;

    @media (min-width: $screen-l) {
      width: 477px;
      padding: 80px 55px;
    }
  }

  &-Logo {
    margin-bottom: 20px;
    @media (min-width: $screen-l) {
      margin-bottom: 10px;
    }
  }

  &-Text {
    display: none;

    @media (min-width: $screen-l) {
      display: block;
      margin-bottom: 80px;
      padding: 0 20px;
      font-size: 18px;
      font-weight: normal;
      line-height: 1.66;
      color: var(--color-text-main);
    }
  }

  &-QrCode {
    display: none;

    @media (min-width: $screen-l) {
      display: block;
      margin-bottom: 116px;
      max-width: 200px;
    }
  }

  &-LinkBox {
    display: flex;
    align-items: center;
  }

  &-LinkCopy {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 1.66;
    color: var(--color-text-main);
    transition: 0.3s;

    &:hover {
      color: var(--color-main1);
    }

    @media (min-width: $screen-l) {
      font-size: 18px;
    }

    span {
      margin-right: 15px;
      @media (min-width: $screen-l) {
        margin-right: 25px;
      }
    }
  }

  &-LinkCopyImg {
    width: 18px;

    @media (min-width: $screen-l) {
      width: 25px;
    }
  }

  &-Btn {
    margin: 50px 0 100px;
  }

  .Item--disable {
    display: none;
    @media (min-width: $screen-l) {
      display: block;
    }
  }

  .Btn-Download {
    width: 100%;
    max-width: 340px;
    @media (min-width: $screen-l) {
      width: auto;
    }
  }
}
</style>
