<template>
  <div class="Wrap-Section">
    <section>
      <div class="HowToInstall">
        <div class="HowToInstall-Title-Block">
          <div class="HowToInstall-Title-Block-Top">Instruction</div>
          <h2 class="HowToInstall-Title-Block-Bottom">How to install</h2>
          <p class="HowToInstall-Title-Block-Description">
            To install any applications not only from Google Play, allow downloads from unknown
            sources:
          </p>
        </div>
        <div class="HowToInstall-Content">
          <div v-for="item in contentInfo" :key="item.id" class="HowToInstall-ContentItem">
            <div class="Img-Wrap">
              <img
                :src="require(`@/assets/img/${item.image}`)"
                alt="image"
                class="HowToInstall-ContentImg"
              />
            </div>
            <p class="Text HowToInstall-ContentText" v-html="item.text"></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HowToInstall',
  data() {
    return {
      contentInfo: [
        {
          image: 'download-app.svg',
          text:
            'Open the site on mobile <br/> or computer and click <br/>download<br/> the application.',
        },
        {
          image: 'qrcode-app.svg',
          text: 'Scan QR code with mobile,<br/> or copy the link to download<br/> the app.',
        },
        { image: 'shield-app.svg', text: 'Allow to install unknown<br/> apps  this source.' },
        {
          image: 'settings-app.svg',
          text:
            'After downloading the<br/> application, follow the<br/> instructions on the screen.',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.Wrap-Section {
  padding-top: 0px !important;
}

.HowToInstall {
  &-Title-Block {
    max-width: 650px;
    margin: 0 auto 50px auto;
    text-align: center;
    color: #fff;

    @media (min-width: $screen-m) {
      margin: 0 auto 75px auto;
    }

    &-Top {
      font-size: 14px;
      font-weight: normal;
      line-height: 17px;
      opacity: 0.5;

      @media (min-width: $screen-m) {
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
      }
    }

    &-Bottom {
      margin: 20px 0 30px 0;
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      text-transform: uppercase;
      @media (min-width: $screen-m) {
        font-size: 56px;
        line-height: 68px;
      }
    }

    &-Description {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.6;
      text-align: center;
      color: var(--color-text-main);
      @media (min-width: $screen-l) {
        font-size: 18px;
      }
    }
  }

  @media (min-width: $screen-m) {
    padding: 102px 0 120px;
  }

  &-Title {
    margin-bottom: 26px;
    @media (min-width: $screen-m) {
      margin-bottom: 30px;
    }
  }

  &-Text {
    width: 100%;
    max-width: 520px;
    margin: 0 auto 80px;
    font-size: 14px;
    line-height: 1.66;
    text-align: center;
    @media (min-width: $screen-m) {
      margin: 0 auto 96px;
      font-size: 18px;
    }
  }

  &-Content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $screen-m) {
      flex-direction: row;
    }
  }

  &-ContentItem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 276px;
    margin-bottom: 25px;
    font-size: 17px;
    line-height: 1.66;
    text-align: center;
    letter-spacing: 0.01em;

    @media (min-width: $screen-m) {
      width: 296px;
      height: 261px;
      margin-bottom: 0;
      padding: 0 10px;
    }

    .Img-Wrap {
      @media (min-width: $screen-m) {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 40%;
          right: calc(-150% + 25px);
          display: none;
          width: 100px;
          height: 2px;
          background: var(--color-main1);
          opacity: 0.5;

          @media (min-width: $screen-xl) {
            display: block;
          }
        }
      }
    }

    &:last-child {
      .Img-Wrap {
        &:after {
          display: none;
        }
      }
    }
  }

  &-ContentImg {
    margin-bottom: 20px;
  }

  &-ContentText {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6;
    color: var(--color-text-main);
    @media (min-width: $screen-m) {
      font-size: 18px;
    }
  }
}
</style>
