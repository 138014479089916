<template>
  <div class="Wrap-Section">
    <section>
      <div class="AwesomeMobile">
        <div class="Info">
          <h1 class="Title">Awesome mobile app <span class="Colored">Ninecasino</span></h1>
          <div class="Description">
            Ninecasino is an online casino where members can enjoy lots of their favourite games
            which include video slots and table games
          </div>
          <button class="Btn Btn--common Btn-Download" @click="$props.show">
            Download
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AwesomeMobile',
  props: {
    urlParams: {
      type: String,
      required: true,
    },
    osDetect: {
      type: String,
      required: true,
    },
    show: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.Wrap-Section {
  padding-top: 0!important;
}
.AwesomeMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: $screen-l) {
    padding: 50px 0;
    background: url('../assets/img/app-landing-awesome.png') right center no-repeat;
    background-size: 459px auto;
  }

  .Info {
    max-width: 420px;
    margin: 0 auto;
    text-align: center;
    color: var(--color-text-main);

    @media (min-width: $screen-s) {
      max-width: 100%;
      margin: 0;
      padding: 0 100px;
    }
    @media (min-width: $screen-l) {
      width: 100%;
      margin-bottom: 70px;
      padding: 0 95px 0 0;
      text-align: left;
    }

    @media (min-width: $screen-l) {
      width: calc(100% - 459px);
    }

    .Title {
      margin: 0;
      font-size: 24px;
      line-height: 29px;

      @media (min-width: $screen-m) {
        font-size: 65px;
        line-height: 79px;
      }
    }

    .Description {
      margin: 25px 0 35px 0;
      font-size: 16px;
      font-weight: normal;
      line-height: 1.3;

      @media (min-width: $screen-m) {
        font-size: 26px;
        line-height: 1.5;
      }
    }

    .Btn-Download {
      width: 100%;
      @media (min-width: $screen-s) {
        width: auto;
      }
    }
  }
}
</style>
