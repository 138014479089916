<template>
  <div class="Wrap-Section">
    <section>
      <div class="New-Tournaments">
        <div class="New-Tournaments-Title-Block">
          <div class="New-Tournaments-Title-Block-Top">Mobile App</div>
          <h2 class="New-Tournaments-Title-Block-Bottom">New tournaments every day</h2>
        </div>
        <div class="New-Tournaments-Info">
          <ul class="New-Tournaments-Info-List">
            <li class="New-Tournaments-Info-List-Item">New tournaments and lotteries every week</li>
            <li class="New-Tournaments-Info-List-Item">
              Great bonuses, exclusive offers and big wins await you
            </li>
          </ul>
          <button class="Btn Btn--common Btn-Download" @click="$props.show">
            Download
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'NewTournaments',
  props: {
    urlParams: {
      type: String,
      required: true,
    },
    osDetect: {
      type: String,
      required: true,
    },
    show: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.width = window.innerWidth;
  },
};
</script>

<style scoped lang="scss">
.Wrap-Section {
  min-height: 615px;
  background: #1f2d61 url('../assets/img/new-tournaments-320.png') no-repeat center bottom;
  background-size: auto 222px;

  @media (min-width: $screen-s) {
    min-height: 700px;
    background: #1f2d61 url('../assets/img/new-tournaments.png') no-repeat center bottom;
    background-size: 1440px auto;
  }
}

.New-Tournaments {
  overflow: hidden;

  &-Title-Block {
    max-width: 650px;
    margin: 0 auto 25px auto;
    text-align: center;
    color: #fff;

    @media (min-width: $screen-m) {
      margin: 0 auto 75px auto;
    }

    &-Top {
      font-size: 14px;
      font-weight: normal;
      line-height: 17px;
      opacity: 0.5;

      @media (min-width: $screen-m) {
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
      }
    }

    &-Bottom {
      margin: 20px 0 30px 0;
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      text-transform: uppercase;
      @media (min-width: $screen-m) {
        font-size: 56px;
        line-height: 68px;
      }
    }
  }

  .New-Tournaments-Info {
    max-width: 420px;
    margin: 0 auto;

    @media (min-width: $screen-m) {
      max-width: 350px;
      margin: 0;
    }
    @media (min-width: $screen-l) {
      max-width: 420px;
    }

    &-List {
      margin: 0 auto 50px auto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #fff;
      letter-spacing: 0.01em;

      @media (min-width: $screen-m) {
        font-size: 24px;
        line-height: 1.5;
      }

      &-Item {
        position: relative;
        margin-bottom: 30px;
        padding-left: 45px;
        text-align: left;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: '';
          position: absolute;
          top: 5px;
          left: 0;
          display: block;
          width: 30px;
          height: 30px;
          background: url('../assets/img/app-check.svg') left top no-repeat;

          @media (min-width: $screen-s) {
            top: 0;
          }
          @media (min-width: $screen-m) {
            top: 15px;
          }
        }
      }
    }

    .Btn-Download {
      width: 100%;
      @media (min-width: $screen-s) {
        display: block;
        width: auto;
        margin: 0 auto;
      }
      @media (min-width: $screen-l) {
        display: inline-block;
      }
    }
  }
}
</style>
