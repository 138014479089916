<template>
  <div class="AppPage">
    <Logo />
    <AwesomeMobile :url-params="getParams" :os-detect="platform" :show="show" />
    <NewTournaments :url-params="getParams" :os-detect="platform" :show="show" />
    <PaymentSystems />
    <OurClients :url-params="getParams" :os-detect="platform" :show="show" />
    <HowToInstall />
    <DownloadApp :url-params="getParams" :os-detect="platform" :show="show" />
  </div>
</template>

<script>
import Logo from '@/components/Logo';
import AwesomeMobile from '@/components/AwesomeMobile';
import NewTournaments from '@/components/NewTournaments';
import PaymentSystems from '@/components/PaymentSystems';
import OurClients from '@/components/OurClients';
import HowToInstall from '@/components/HowToInstall';
import DownloadApp from '@/components/DownloadApp';
import { platform, getParams, show } from '@/scripts/script';

export default {
  name: 'AppPage',
  components: {
    Logo,
    AwesomeMobile,
    NewTournaments,
    PaymentSystems,
    OurClients,
    HowToInstall,
    DownloadApp,
  },
  layout: 'page',
  data() {
    return {};
  },
  head() {
    return {
      title: 'Awesome mobile app Ninecasino',
      meta: [
        {
          description: 'Ninecasino is an online casino where members can enjoy lots of their favourite games which include video slots and table games',
        },
      ],
    };
  },
  computed: {
    platform,
    getParams,
  },
  methods: {
    show,
  },
};
</script>

<style lang="scss">
.AppPage {
  background: url('./assets/img/app-landing-awesome-bg-small.png') right top no-repeat;
  background-size: 290px 290px;

  @media (min-width: $screen-s) {
    background-size: 290px 290px;
  }
  @media (min-width: $screen-l) {
    background: initial;
  }

  .Wrap-Section {
    padding: 50px 0;
  }

  .Btn-Download {
    font-size: 18px;
    @media (min-width: $screen-l) {
      padding: 27px 56px;
      font-size: 16px;
      line-height: 1.2;
    }
  }
}
</style>
