<template>
  <div class="Wrap-Section">
    <section>
      <div class="OurClients">
        <div class="OurClients-Title-Block">
          <div class="OurClients-Title-Block-Top">Testimonials</div>
          <h2 class="OurClients-Title-Block-Bottom">Our Clients</h2>
        </div>
        <div class="OurClients-Content">
          <div v-for="info in cardInfo" :key="info.id" class="OurClients-Card">
            <div class="OurClients-CardMember">
              <div class="OurClients-CardMemberBox">
                <div class="OurClients-CardMemberName">
                  {{ info.name }}
                </div>
                <div class="OurClients-CardMemberDate">
                  {{ info.date }}
                </div>
              </div>
            </div>
            <img
              :src="require(`@/assets/img/${info.rate}`)"
              alt="rating"
              class="OurClients-CardRating"
            />
            <p class="Text OurClients-CardText" v-html="info.text"></p>
          </div>
        </div>
        <div v-if="width < 768" class="Download-Button">
          <div class="Download-Button-Text">Download the mobile app</div>
          <button class="Btn Btn--common Btn-Download" @click="$props.show">
            Download
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { width } from "../scripts/script";

export default {
  name: 'OurClients',
  props: {
    urlParams: {
      type: String,
      required: true,
    },
    osDetect: {
      type: String,
      required: true,
    },
    show: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      cardInfo: [
        {
          name: 'gamlet',
          date: '12.07.2020',
          rate: 'app-four-full-star.svg',
          text:
            'Nice casino with cozy design. Wide range of games from the popular providers which is helping to find something interesting every time.',
        },
        {
          name: 'cristopher',
          date: '20.12.2020',
          rate: 'app-five-full-star.svg',
          text:
            'Big choice of slots and live games. Support was very friendly and helped me to receive my withdrawal fast. Will back to this casino soon.',
        },
        {
          name: 'oleg',
          date: '02.02.2021',
          rate: 'app-four-full-star.svg',
          text:
            'I accidentally found this casino and I think I’m lucky because of that. Welcome bonus was nice and I won a jackpot. Hope next time I will do it again.',
        },
      ],
    };
  },
  computed: {
    width,
  },
};
</script>

<style scoped lang="scss">
.Wrap-Section {
  padding-top: 25px !important;
  @media (min-width: $screen-m) {
    padding-top: 50px !important;
  }
}

.OurClients {
  &-Title-Block {
    max-width: 650px;
    margin: 0 auto 25px auto;
    text-align: center;
    color: #fff;

    @media (min-width: $screen-m) {
      margin: 0 auto 50px auto;
    }

    &-Top {
      font-size: 14px;
      font-weight: normal;
      line-height: 17px;
      opacity: 0.5;

      @media (min-width: $screen-m) {
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
      }
    }

    &-Bottom {
      margin: 20px 0 30px 0;
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      text-transform: uppercase;
      @media (min-width: $screen-m) {
        font-size: 56px;
        line-height: 68px;
      }
    }
  }

  &-Content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: $screen-l) {
      flex-direction: row;
    }
  }

  &-Card {
    width: 100%;
    margin-bottom: 15px;
    padding: 40px 25px 50px 25px;
    color: #fff;
    border: 2px solid var(--color-most-faded);
    border-radius: 40px;

    @media (min-width: $screen-l) {
      max-width: 376px;
      margin: 20px;
      padding: 50px 40px 75px 40px;
    }
  }

  &-CardMember {
    display: flex;
    align-items: center;
  }

  &-CardMemberName {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.25;
    text-transform: uppercase;

    @media (min-width: $screen-m) {
      font-size: 24px;
    }
  }

  &-CardMemberDate {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.25;
    opacity: 0.7;

    @media (min-width: $screen-m) {
      font-size: 18px;
    }
  }

  &-CardText {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6;
    color: var(--color-text-main);

    @media (min-width: $screen-m) {
      margin-bottom: 0;
      font-size: 18px;
    }
  }

  &-CardRating {
    margin: 20px 0;
  }

  .Download-Button {
    margin: 50px -16px 0 -16px;
    padding: 50px 16px 60px 16px;
    text-align: center;
    background-color: #1a2653;

    &-Text {
      max-width: 420px;
      margin: 0 auto 30px auto;
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      text-align: center;
      color: var(--color-text-main);
      text-transform: uppercase;
    }

    .Btn-Download {
      width: 100%;
      max-width: 420px;
      @media (min-width: $screen-s) {
        display: block;
        width: auto;
        margin: 0 auto;
      }
      @media (min-width: $screen-l) {
        display: inline-block;
      }
    }
  }
}
</style>
