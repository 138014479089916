import Modal from "@/components/AppModal";

export function show() {
    this.$modal.show(
        Modal,
        {},
        {
            name: 'modal',
            shiftY: .5,
            shiftX: .5,
            width: 870,
            height: 'auto',
            scrollable: true,
            'adaptive': true,
        },
        {},
    );
}

export function width() {
    return window.innerWidth;
}

export function platform() {
    const userAgent = navigator.userAgent;
    if (/iPad|iPhone|iPod|Mobi|Android/i.test(userAgent)) {
        return 'mobile';
    }
    return 'desktop'
}

export function getParams() {
    const url = new URL(window.location.href);
    const source = url.searchParams.get("source");
    const params = window.location.search;
    let resultUrl = 'https://ninecas.onelink.me/B1pT/f6b0f98c/'; // for source = email or default

    if(source) {
        switch (source.toLowerCase()) {
            case 'popup': { // for source = popup
                resultUrl = 'https://ninecas.onelink.me/B1pT/f078f43d/';
                break;
            }
            case 'bottom': { // for source = bottom
                resultUrl = 'https://ninecas.onelink.me/B1pT/c74573f4/';
                break;
            }
            default: {
                break;
            }
        }
    }

    return resultUrl + (params ? `${params}&campaign_id=nine` : '?campaign_id=nine')
}